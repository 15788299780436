import React from 'react';

import { Heading } from '../../../styleguide';
import { TabContentWrapper } from '../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../styles/orders-cards';
import TabPanel from '../../shop/tabs/TabPanel';
import { AttributedCard } from './Cards';
import EmptyTab from './EmptyTab';

const InProgressTab = props => {
  const { value, orders } = props;

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes attribués</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return <AttributedCard key={order.id} order={order} />;
              })}
            </OrdersListWrapper>
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle attribué'}
              description={
                'Vous retrouverez ici vos commandes attribué à récupérer 📦'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default InProgressTab;
