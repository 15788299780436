import React from 'react';
import { Chip } from '@material-ui/core';

import {
  computeEstimatedTime,
  computeTimeFrom,
} from '../../../actions/orders/helpers/computeTimesHelpers';
import {
  Button,
  HeaderCard,
  ItemListing,
  OrderDetails,
  OrderTimeDetails,
  RusherOrderTimeDetails,
  OrderTitle,
  OrderTimeEnded,
  Text,
} from '../../../styleguide';
import { useTheme } from '../../../hooks';
import computePreparationTime from '../../../actions/orders/helpers/computePreparationTime';
import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import {
  OrderCardOverlay,
  OrderCardWrapper,
  OrderCardActions,
  OrderCardHeader,
} from '../../../styles/orders-cards';
import { ClockIcon } from '../../../styleguide/icons';
import { parseNumber } from '../../../common';

const NewOrderCard = props => {
  const { order, onClickConfirm, canTakeNewOrder } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="rusher" />
      <OrderTitle order={order} withShopName />

      <RusherOrderTimeDetails
        order={order}
        type={order.status === ORDER_STATUS.ready ? 'ready' : 'in-progress'}
        timeColor={
          order.status === ORDER_STATUS.ready ? theme.red : theme.primaryColor
        }
      />
      <ItemListing order={order} expanded={false} />

      <OrderDetails order={order} kind="rusher" />
      <OrderCardActions theme={theme}>
        <Button
          onClick={onClickConfirm}
          small
          disabled={!canTakeNewOrder || temporaryDisabled}
        >
          S'attribuer la commande
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const AttributedCard = props => {
  const { order } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="rusher" />
      <OrderTitle order={order} withShopName />

      <ItemListing order={order} />

      <RusherOrderTimeDetails
        order={order}
        type={order.status === ORDER_STATUS.ready ? 'ready' : 'in-progress'}
        timeColor={
          order.status === ORDER_STATUS.ready ? theme.red : theme.primaryColor
        }
      />
      <OrderDetails order={order} kind="rusher" />
    </OrderCardWrapper>
  );
};

const DeliveryCard = props => {
  const { order, onClickConfirm, isLoading } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="rusher" />
      <OrderTitle order={order} withShopName />

      <RusherOrderTimeDetails
        order={order}
        timeColor={theme.primaryColor}
        type="delivery"
      />
      <ItemListing order={order} expanded={false} />

      <OrderDetails order={order} kind="rusher" />
      <OrderCardActions theme={theme}>
        <Button
          onClick={onClickConfirm}
          small
          isLoading={isLoading}
          disabled={temporaryDisabled}
        >
          Terminer
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const InProgressOrderCard = props => {
  const { order, onClickConfirm } = props;
  const theme = useTheme();
  const orderFrom = computeTimeFrom({ ts: order.orderAt });

  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.acceptedAt,
    withDelay: false,
    minutes: preparationTime,
    seconds: order.duration,
  });

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardHeader>
        <Chip icon={<ClockIcon />} label={estimatedDeliveryTime} />
        <Chip icon={<ClockIcon />} label={orderFrom} />
      </OrderCardHeader>
      <OrderTitle order={order} withShopName />

      <OrderTimeDetails order={order} timeColor={theme.primaryColor} />
      <ItemListing order={order} />
      <OrderDetails order={order} type="in-progress" />
      <OrderCardActions theme={theme}>
        <Button onClick={onClickConfirm} small>
          Terminer
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const ReadyOrderCard = props => {
  const { order, onClickConfirm } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} />
      <OrderTitle order={order} withShopName />

      <OrderTimeDetails order={order} timeColor={theme.primaryColor} isReady />
      <ItemListing order={order} expanded={false} />
      <OrderDetails type="ready" order={order} />
      <OrderCardActions theme={theme}>
        <Button onClick={onClickConfirm} small>
          Départ
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const InDeliveryCard = props => {
  const { order, onClickConfirm } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} />
      <OrderTitle order={order} withShopName />

      <OrderTimeDetails
        order={order}
        timeColor={theme.primaryColor}
        inDelivery
      />
      <ItemListing order={order} expanded={false} />
      <OrderDetails type="ready" order={order} />
      {/* TODO: Remove this when rusher flow is done */}
      <OrderCardActions theme={theme}>
        <Button onClick={onClickConfirm} small>
          Départ
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const DeliveredCard = props => {
  const { order } = props;
  const theme = useTheme();
  const amountPaid = `${parseNumber(order.deliveryFees).toFixed(2)}€ HT`;

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardOverlay>
        <Text
          size={400}
          styles={{
            color: theme.primaryColor,
            backgroundColor: theme.cardBg,
            fontWeight: '500',
            whiteSpace: 'nowrap',
          }}
        >
          Commande livrée:{' '}
          <span style={{ fontWeight: 'bold' }}>+{amountPaid}</span>
          {' 🎉'}
        </Text>
      </OrderCardOverlay>
      <HeaderCard order={order} kind="rusher" />
      <OrderTitle order={order} withShopName />

      <OrderTimeEnded order={order} />

      <OrderDetails order={order} kind="rusher" expanded={false} />
    </OrderCardWrapper>
  );
};

export {
  NewOrderCard,
  InProgressOrderCard,
  ReadyOrderCard,
  InDeliveryCard,
  DeliveredCard,
  AttributedCard,
  DeliveryCard,
};
