import React from 'react';
import { Chip, Avatar } from '@material-ui/core';
import { DateTime } from 'luxon';
import { add } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';

import { parseNumber } from '../../common';
import { useTheme } from '../../hooks';
import ORDER_STATUS from '../../actions/orders/helpers/STATUS';
import getReadableStatus from '../../actions/orders/helpers/getReadableStatus';
import {
  computeTimeFrom,
  computeEstimatedTime,
  displayTimeFrom,
  displayDurationFrom,
} from '../../actions/orders/helpers/computeTimesHelpers';
import computePreparationTime from '../../actions/orders/helpers/computePreparationTime';
import { computeFinalPreparationTime } from '../../actions/orders/helpers/computeFinalPreparationTime';
import Heading from '../heading';
import StopWatch from '../stopwatch';
import { EuroIcon, ClockIcon, PlusCircleIcon, MinusIcon } from '../icons';
import Text from '../text';
import {
  OrderCardHeader,
  OrderCardItemsListWrapper,
  OrderCardDetails,
  OrderCardDetailsRow,
  OrderTitleWrapper,
  OrderMessageWrapper,
  ActionWrapper,
} from '../../styles/orders-cards';
import { SPACING } from '../../styles/constants';
import MenuLine from './MenuLine';
import ProductLine from './ProductLine';
import {
  ExternalHoverableLink,
  SimpleHoverableAction,
} from '../hoverable-link';
import { getInitialDeliveryFees } from '../../common/fees';

/**
  * Cards Skeletons:
  * 
  *  The following components are designed to compose Orders Card for shop and rusher following.
  *  You need to use orders-cards styles to create complete cards

  *  Example:
  *  <OrderCardWrapper>
  *  <HeaderCard />
  *  <ItemListing />
  *  <OrderDetails />
  *  <OrderCardOverlay>
  *    <Text></Text>
  *  </OrderCardOverlay>
  *  </OrderCardWrapper>
 */

const ExpandBlockButton = ({ onClick, expanded, children }) => {
  return (
    <SimpleHoverableAction
      onClick={onClick}
      styles={{
        margin: '0 auto',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      {!!expanded ? <MinusIcon /> : <PlusCircleIcon />}
      {children}
    </SimpleHoverableAction>
  );
};

const HeaderCard = props => {
  const { order, kind } = props;
  const theme = useTheme();
  const orderFrom = computeTimeFrom({ ts: order.orderAt });

  if (kind === 'rusher') {
    const status = getReadableStatus(order.status);
    const isReady = order.status === ORDER_STATUS.ready;
    const deliveryFees = order.discount
      ? getInitialDeliveryFees(order.distance)
      : order.deliveryFees;

    return (
      <OrderCardHeader>
        <Chip
          icon={<EuroIcon />}
          label={`Montant : ${parseNumber(deliveryFees).toFixed(2)}€`}
        />
        <Chip
          label={status}
          variant="outlined"
          style={{ color: isReady ? theme.red : theme.headingColor }}
        />
      </OrderCardHeader>
    );
  }

  if (kind === 'admin') {
    const computedFees = parseNumber(
      add(parseNumber(order.deliveryFees), parseNumber(order.appFees))
    );
    const computedTotal = parseNumber(
      add(parseNumber(order.total), parseNumber(computedFees))
    );

    return (
      <OrderCardHeader>
        <Chip icon={<EuroIcon />} label={computedTotal} />
        <Chip icon={<ClockIcon />} label={orderFrom} />
      </OrderCardHeader>
    );
  }

  return (
    <OrderCardHeader>
      <Chip icon={<EuroIcon />} label={order.total} />
      <Chip icon={<ClockIcon />} label={orderFrom} />
    </OrderCardHeader>
  );
};

const ItemListing = props => {
  const { order, expanded } = props;
  const [isExpanded, setIsExpanded] = React.useState(
    expanded !== undefined ? expanded : true
  );
  const theme = useTheme();

  return (
    <OrderCardItemsListWrapper theme={theme}>
      <Heading size={500} styles={{ padding: `${SPACING[100]} 0` }}>
        Produits:{' '}
      </Heading>

      {isExpanded ? (
        <>
          {order.itemsList.map((shopItem, index) =>
            shopItem.item.isMenu ? (
              <MenuLine key={index} shopItem={shopItem} withoutImg />
            ) : (
              <ProductLine key={index} shopItem={shopItem} withoutImg />
            )
          )}

          {order.message ? (
            <>
              <Heading size={500} styles={{ padding: `${SPACING[100]} 0` }}>
                Instructions:{' '}
              </Heading>

              <OrderMessageWrapper bg={theme.lightGray}>
                <Text muted size={400}>
                  {order.message}
                </Text>
              </OrderMessageWrapper>
            </>
          ) : null}
          <ActionWrapper>
            <ExpandBlockButton
              expanded={true}
              onClick={() => setIsExpanded(false)}
            >
              Voir moins
            </ExpandBlockButton>
          </ActionWrapper>
        </>
      ) : (
        <ActionWrapper>
          <ExpandBlockButton
            expanded={false}
            onClick={() => setIsExpanded(true)}
          >
            Voir les produits
          </ExpandBlockButton>
        </ActionWrapper>
      )}
    </OrderCardItemsListWrapper>
  );
};

const OrderTimeDetails = props => {
  const { order, timeColor, isReady, inDelivery } = props;
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const theme = useTheme();

  if (inDelivery) {
    const deliveryDuration = displayDurationFrom({ seconds: order.duration });

    return (
      <OrderCardDetails>
        <StopWatch
          message={'En livraison depuis : '}
          startTS={order.pickedAt}
          color={timeColor}
        />
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Temps livraison estimé:
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {deliveryDuration}
          </Text>
        </OrderCardDetailsRow>
      </OrderCardDetails>
    );
  }

  if (isReady) {
    const estimatedDeliveryTime = computeEstimatedTime({
      ts: order.readyAt,
      withDelay: true,
      seconds: order.duration,
    });

    return (
      <OrderCardDetails>
        <StopWatch
          message={'Prête depuis : '}
          startTS={order.readyAt}
          color={timeColor}
        />
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Heure livraison estimé:
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {estimatedDeliveryTime}
          </Text>
        </OrderCardDetailsRow>
      </OrderCardDetails>
    );
  }

  return (
    <OrderCardDetails>
      <StopWatch startTS={order.acceptedAt} color={timeColor} />
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          T.P. estimé:
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {preparationTime}mn
        </Text>
      </OrderCardDetailsRow>
    </OrderCardDetails>
  );
};

const RusherOrderTimeDetails = props => {
  const { order, timeColor, type } = props;
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const theme = useTheme();

  if (type === 'in-progress') {
    const estimatedPickUpTime = computeEstimatedTime({
      ts: order.acceptedAt,
      withDelay: true,
      minutes: preparationTime,
    });

    const estimatedDeliveryTime = computeEstimatedTime({
      ts: order.acceptedAt,
      withDelay: true,
      minutes: preparationTime,
      seconds: order.duration,
    });

    return (
      <OrderCardDetails topBorder theme={theme}>
        <StopWatch
          message={'En préparation depuis : '}
          startTS={order.acceptedAt}
          color={timeColor}
        />
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Temps préparation estimé:
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {preparationTime}mn
          </Text>
        </OrderCardDetailsRow>
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Heure récupération estimé:
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {estimatedPickUpTime}
          </Text>
        </OrderCardDetailsRow>
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Heure de livraison estimé
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {estimatedDeliveryTime}
          </Text>
        </OrderCardDetailsRow>
      </OrderCardDetails>
    );
  }

  if (type === 'delivery') {
    const { hours, minutes } = computeFinalPreparationTime(
      order.acceptedAt,
      order.readyAt
    );
    const finalDeliveryTime = computeEstimatedTime({
      ts: order.pickedAt,
      withDelay: false,
      minutes: 0,
      seconds: order.duration,
    });
    const pickedAt = displayTimeFrom({ ts: order.pickedAt });

    return (
      <OrderCardDetails topBorder theme={theme}>
        <StopWatch
          message={'En livraison depuis : '}
          startTS={order.pickedAt}
          color={timeColor}
        />
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Temps préparation :
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
          </Text>
        </OrderCardDetailsRow>
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Heure récupération:
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {pickedAt}
          </Text>
        </OrderCardDetailsRow>
        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Heure de livraison estimé
          </Text>{' '}
          <Text size={300} styles={{ textAlign: 'right' }}>
            {finalDeliveryTime}
          </Text>
        </OrderCardDetailsRow>
      </OrderCardDetails>
    );
  }

  const deliveryDuration = displayDurationFrom({ seconds: order.duration });
  const estimatedPickUpTime = computeEstimatedTime({
    ts: order.readyAt,
    withDelay: true,
  });
  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.readyAt,
    withDelay: true,
    minutes: 0,
    seconds: order.duration,
  });

  return (
    <OrderCardDetails topBorder theme={theme}>
      <StopWatch
        message={'En attente depuis : '}
        startTS={order.readyAt}
        color={timeColor}
      />
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Heure récupération max:
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {estimatedPickUpTime}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Heure de livraison estimé
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {estimatedDeliveryTime}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Temps de livraison estimé
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {deliveryDuration}
        </Text>
      </OrderCardDetailsRow>
    </OrderCardDetails>
  );
};

const OrderDetails = props => {
  const { order, type, kind, expanded } = props;
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(
    expanded !== undefined ? expanded : true
  );

  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const { hours, minutes } = computeFinalPreparationTime(
    order.acceptedAt,
    order.readyAt
  );
  const estimatedDeliveryTime = displayDurationFrom({
    seconds: order.duration,
  });
  const clientPhone = !!order.clientPhone
    ? parsePhoneNumber(order.clientPhone, 'FR').format('INTERNATIONAL')
    : '0000000000';

  if (kind === 'rusher') {
    return (
      <OrderCardDetails>
        <Heading size={500} styles={{ padding: `${SPACING[100]} 0` }}>
          Informations:{' '}
        </Heading>

        {isExpanded ? (
          <>
            {!!order.rusher && (
              <>
                <OrderCardDetailsRow bottomBorder>
                  <Text size={300} muted styles={{ width: '60%' }}>
                    Tel client:
                  </Text>{' '}
                  <Text size={300} styles={{ textAlign: 'right' }}>
                    {!!clientPhone ? (
                      <ExternalHoverableLink
                        withoutTarget
                        href={`tel:${clientPhone}`}
                      >
                        {clientPhone}
                      </ExternalHoverableLink>
                    ) : (
                      '0000000000'
                    )}
                  </Text>
                </OrderCardDetailsRow>
                <OrderCardDetailsRow bottomBorder>
                  <Text size={300} muted styles={{ width: '68%' }}>
                    Adresse livraison:
                  </Text>{' '}
                  <Text size={300} styles={{ textAlign: 'right' }}>
                    <ExternalHoverableLink
                      href={`http://maps.google.com/?q=${`${order.selectedAddress.street} ${order.selectedAddress.postalCode} ${order.selectedAddress.city}`}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {order.selectedAddress.street},{' '}
                      {order.selectedAddress.postalCode}{' '}
                      {order.selectedAddress.city}
                    </ExternalHoverableLink>
                  </Text>
                </OrderCardDetailsRow>
              </>
            )}

            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Prix:
              </Text>{' '}
              <Text size={300} styles={{ textAlign: 'right' }}>
                {parseNumber(order.total).toFixed(2)}€
              </Text>
            </OrderCardDetailsRow>

            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Nombres d'article{order.itemsList.length > 1 ? 's' : ''}:{' '}
              </Text>
              <Text size={300} styles={{ textAlign: 'right' }}>
                {order.itemsList.length}
              </Text>
            </OrderCardDetailsRow>

            {type !== 'in-delivery' ? (
              <OrderCardDetailsRow bottomBorder>
                <Text size={300} muted styles={{ width: '68%' }}>
                  Temps de livraison estimé:{' '}
                </Text>
                <Text size={300} styles={{ textAlign: 'right' }}>
                  {estimatedDeliveryTime}
                </Text>
              </OrderCardDetailsRow>
            ) : null}

            {type === 'in-delivery' ? (
              <OrderCardDetailsRow bottomBorder>
                <Text size={300} muted styles={{ width: '68%' }}>
                  Temps de préparation:
                </Text>{' '}
                <Text size={300} styles={{ textAlign: 'right' }}>
                  {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
                </Text>
              </OrderCardDetailsRow>
            ) : null}

            {type !== 'in-delivery' ? (
              <OrderCardDetailsRow bottomBorder>
                <Text size={300} muted styles={{ width: '68%' }}>
                  Adresse shop:
                </Text>{' '}
                <Text size={300} styles={{ textAlign: 'right' }}>
                  <ExternalHoverableLink
                    href={`http://maps.google.com/?q=${`${order.shop.address.street} ${order.shop.address.postalCode} ${order.shop.address.city}`}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.shop.address.street}, {order.shop.address.postalCode}{' '}
                    {order.shop.address.city}
                  </ExternalHoverableLink>
                </Text>
              </OrderCardDetailsRow>
            ) : null}
            {expanded !== undefined && (
              <ActionWrapper>
                <ExpandBlockButton
                  expanded={true}
                  onClick={() => setIsExpanded(false)}
                >
                  Voir moins
                </ExpandBlockButton>
              </ActionWrapper>
            )}
          </>
        ) : (
          <ActionWrapper>
            <ExpandBlockButton
              expanded={false}
              onClick={() => setIsExpanded(true)}
            >
              Voir les détails
            </ExpandBlockButton>
          </ActionWrapper>
        )}

        <OrderCardDetailsRow theme={theme}>
          <Text size={300} muted styles={{ width: '68%' }}>
            Identifiant:{' '}
          </Text>
          <Text size={300} styles={{ textAlign: 'right' }}>
            {order.id}
          </Text>
        </OrderCardDetailsRow>
      </OrderCardDetails>
    );
  }

  return (
    <OrderCardDetails>
      <Heading size={500} styles={{ padding: `${SPACING[100]} 0` }}>
        Informations:{' '}
      </Heading>

      {isExpanded ? (
        <>
          <OrderCardDetailsRow bottomBorder>
            <Text size={300} muted styles={{ width: '60%' }}>
              Tel client:
            </Text>{' '}
            <Text size={300} styles={{ textAlign: 'right' }}>
              {!!clientPhone ? (
                <ExternalHoverableLink
                  withoutTarget
                  href={`tel:${clientPhone}`}
                >
                  {clientPhone}
                </ExternalHoverableLink>
              ) : (
                '0000000000'
              )}
            </Text>
          </OrderCardDetailsRow>

          {type === 'in-progress' && kind !== 'admin' ? null : (
            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Adresse du client:
              </Text>{' '}
              <Text size={300} styles={{ textAlign: 'right' }}>
                {kind !== 'shop' ? (
                  <ExternalHoverableLink
                    href={`http://maps.google.com/?q=${`${order.selectedAddress.street} ${order.selectedAddress.postalCode} ${order.selectedAddress.city}`}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.selectedAddress.street}, {order.selectedAddress.city}
                  </ExternalHoverableLink>
                ) : (
                  <>
                    {order.selectedAddress.street}, {order.selectedAddress.city}
                  </>
                )}
              </Text>
            </OrderCardDetailsRow>
          )}

          {kind !== 'admin' ? null : (
            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Adresse du shop:
              </Text>{' '}
              <Text size={300} styles={{ textAlign: 'right' }}>
                <ExternalHoverableLink
                  href={`http://maps.google.com/?q=${`${order.shop.address.street} ${order.shop.address.postalCode} ${order.shop.address.city}`}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.shop.address.street}, {order.shop.address.city}
                </ExternalHoverableLink>
              </Text>
            </OrderCardDetailsRow>
          )}

          {type === 'in-progress' && kind === 'admin' ? (
            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Prix:
              </Text>{' '}
              <Text size={300} styles={{ textAlign: 'right' }}>
                {parseNumber(order.total).toFixed(2)}€
              </Text>
            </OrderCardDetailsRow>
          ) : null}

          <OrderCardDetailsRow bottomBorder>
            <Text size={300} muted styles={{ width: '68%' }}>
              Nombres d'article{order.itemsList.length > 1 ? 's' : ''}:{' '}
            </Text>
            <Text size={300} styles={{ textAlign: 'right' }}>
              {order.itemsList.length}
            </Text>
          </OrderCardDetailsRow>

          {type === 'in-progress' || type === 'ready' ? null : (
            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Temps de préparation estimé:{' '}
              </Text>
              <Text size={300} styles={{ textAlign: 'right' }}>
                {preparationTime}mn
              </Text>
            </OrderCardDetailsRow>
          )}

          {type === 'ready' ? (
            <OrderCardDetailsRow bottomBorder>
              <Text size={300} muted styles={{ width: '68%' }}>
                Temps de préparation:
              </Text>{' '}
              <Text size={300} styles={{ textAlign: 'right' }}>
                {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
              </Text>
            </OrderCardDetailsRow>
          ) : null}

          <OrderCardDetailsRow bottomBorder>
            <Text size={300} muted styles={{ width: '68%' }}>
              Livreur:{' '}
            </Text>
            {!!order.rusher ? (
              <Chip
                variant="outlined"
                color="secondary"
                label={order.rusher.name}
                avatar={<Avatar src={order.rusher.pictureURL} />}
              />
            ) : (
              <Text
                size={300}
                styles={{
                  textAlign: 'right',
                  ...(!order.rusher ? { color: theme.red } : {}),
                }}
              >
                Non attribué
              </Text>
            )}
          </OrderCardDetailsRow>
          {expanded !== undefined && (
            <ActionWrapper>
              <ExpandBlockButton
                expanded={true}
                onClick={() => setIsExpanded(false)}
              >
                Voir moins
              </ExpandBlockButton>
            </ActionWrapper>
          )}
        </>
      ) : (
        <ActionWrapper>
          <ExpandBlockButton
            expanded={false}
            onClick={() => setIsExpanded(true)}
          >
            Voir les détails
          </ExpandBlockButton>
        </ActionWrapper>
      )}
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Identifiant:{' '}
        </Text>
        <Text size={300} styles={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
          {order.id}
        </Text>
      </OrderCardDetailsRow>
    </OrderCardDetails>
  );
};

const OrderTitle = props => {
  const { order, withShopName } = props;
  const name = !!order.name ? order.name : 'Nom du client';
  const theme = useTheme();

  return (
    <OrderTitleWrapper theme={theme}>
      <Heading
        size={600}
        styles={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </Heading>
      {withShopName ? (
        <Heading
          size={500}
          styles={{ marginTop: SPACING[100], color: theme.textColor }}
        >
          {order.shop.name}
        </Heading>
      ) : null}
    </OrderTitleWrapper>
  );
};

const OrderTimeEnded = props => {
  const { order } = props;
  const theme = useTheme();
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const { hours, minutes } = computeFinalPreparationTime(
    order.acceptedAt,
    order.readyAt
  );
  const pickedAt = displayTimeFrom({ ts: order.pickedAt });
  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.acceptedAt,
    withDelay: true,
    minutes: preparationTime,
    seconds: order.duration,
  });
  const finalDeliveryTime = computeEstimatedTime({
    ts: order.deliverAt,
    withDelay: false,
    minutes: 0,
    seconds: 0,
  });
  const orderAtD = DateTime.fromMillis(order.orderAt).toLocaleString(
    DateTime.DATETIME_SHORT
  );

  return (
    <OrderCardDetails topBorder theme={theme}>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '50%' }}>
          Commandé le:
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {orderAtD}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Temps préparation:
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Heure de récuperation
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {pickedAt}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Heure de livraison estimé
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {estimatedDeliveryTime}
        </Text>
      </OrderCardDetailsRow>
      <OrderCardDetailsRow theme={theme}>
        <Text size={300} muted styles={{ width: '68%' }}>
          Heure de livraison final
        </Text>{' '}
        <Text size={300} styles={{ textAlign: 'right' }}>
          {finalDeliveryTime}
        </Text>
      </OrderCardDetailsRow>
    </OrderCardDetails>
  );
};

export {
  HeaderCard,
  ItemListing,
  OrderDetails,
  OrderTimeDetails,
  RusherOrderTimeDetails,
  OrderTitle,
  OrderTimeEnded,
};
